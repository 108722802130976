var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "fragment",
    [
      _c(
        "v-toolbar",
        { attrs: { dark: "", color: "primary" } },
        [
          _c(
            "v-container",
            { staticClass: "d-flex align-center pa-0" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-3",
                  attrs: { small: "", icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("back")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                1
              ),
              _c("v-toolbar-title", { staticClass: "mt-1" }, [
                _c("h1", { staticClass: "title font-weight-bold" }, [
                  _vm._v("Regiões"),
                ]),
              ]),
              _c("v-spacer"),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-container",
        { staticClass: "pa-5 pt-0 mt-5" },
        [
          _c("base-dialog-select-state", {
            attrs: { estadoAtual: _vm.estado },
            on: { select: _vm.selecionaEstado },
          }),
          _vm.loading
            ? _c(
                "div",
                { staticClass: "d-flex justify-center mt-10" },
                [
                  _c("v-progress-circular", {
                    attrs: { color: "blue", indeterminate: "" },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          staticClass: "d-flex",
          attrs: {
            fullscreen: "",
            "hide-overlay": "",
            transition: "dialog-bottom-transition",
          },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "themelogout",
              style: {
                background: _vm.$store.getters.getColor("bgColorSecundary"),
              },
            },
            [
              _vm.showEstado
                ? _c("Corretoras", {
                    attrs: { regiao: _vm.regiaoSelecionada },
                    on: { back: _vm.closeDialog, complete: _vm.complete },
                  })
                : _vm._e(),
              _vm._v(" " + _vm._s(_vm.regiaoSelecionada) + " "),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }