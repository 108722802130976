var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "fragment",
    [
      _c(
        "v-toolbar",
        { attrs: { dark: "", color: "primary" } },
        [
          _c(
            "v-container",
            { staticClass: "d-flex align-center pa-0" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-3",
                  attrs: { small: "", icon: "", dark: "" },
                  on: { click: _vm.complete },
                },
                [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                1
              ),
              _c("v-toolbar-title", { staticClass: "mt-1" }, [
                _c("h1", { staticClass: "title font-weight-bold" }, [
                  _vm._v(_vm._s(_vm.regiao.nome)),
                ]),
              ]),
              _c("v-spacer"),
              _c(
                "v-toolbar-items",
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "d-flex align-center",
                      attrs: { small: "", dark: "", text: "" },
                      on: { click: _vm.complete },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "text-capitalize d-block mt-1" },
                        [_vm._v("Concluir")]
                      ),
                      _c(
                        "v-icon",
                        { staticClass: "ml-2", attrs: { size: "18" } },
                        [_vm._v("mdi-check-circle-outline")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-container",
        { staticClass: "pa-5 pt-0 mt-5" },
        [
          _c(
            "v-form",
            [
              _c("v-text-field", {
                attrs: { name: "busca", label: "Filtrar", clearable: "" },
                on: { input: _vm.search },
              }),
            ],
            1
          ),
          _vm.loading
            ? _c(
                "div",
                { staticClass: "d-flex justify-center mt-10" },
                [
                  _c("v-progress-circular", {
                    attrs: { color: "blue", indeterminate: "" },
                  }),
                ],
                1
              )
            : _c(
                "v-list-item-group",
                {
                  attrs: { color: "primary", multiple: "" },
                  model: {
                    value: _vm.corretoras,
                    callback: function ($$v) {
                      _vm.corretoras = $$v
                    },
                    expression: "corretoras",
                  },
                },
                _vm._l(_vm.dadosFiltrados, function (corretora, index) {
                  return _c("v-list-item", {
                    key: index,
                    attrs: {
                      disabled:
                        !_vm.corretoras.includes(corretora.cpfCnpj) &&
                        _vm.corretoras.length >= _vm.total,
                      value: corretora.cpfCnpj,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ active }) {
                            return [
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _c(
                                      "h2",
                                      {
                                        staticClass:
                                          "white--text subtitle-1 font-weight-bold ma-1 ml-0",
                                      },
                                      [_vm._v(_vm._s(corretora.nomeFantasia))]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-action",
                                [
                                  _c("div", {
                                    class: active ? "item-ativo" : "",
                                  }),
                                  _c("v-checkbox", {
                                    staticClass: "d-none",
                                    attrs: {
                                      disabled:
                                        3 > _vm.corretoras.length || active,
                                      "input-value": active,
                                      color: "deep-purple accent-4",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  })
                }),
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }