export default {
  data() {
    return {
      isEditing: false,
    };
  },
  methods: {
    mixinPreventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = '';
    },
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.mixinPreventNav);
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('beforeunload', this.mixinPreventNav);
    });
  },
};
