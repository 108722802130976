<template>
  <fragment>
    <slot :data="estados" :loading="loading"></slot>
  </fragment>
</template>

<script>
import catalogoService from '@/services/catalogoService';

export default {
  name: 'AsyncEstados',
  data() {
    return {
      loading: false,
      estados: [],
    };
  },
  props: {
    estadoAtual: {
      type: String,
    },
  },
  methods: {
    async getData() {
      try {
        this.loading = true;
        const data = await catalogoService.getEstados();
        if (this.estadoAtual) {
          const atual = data.find((d) => d.id === this.estadoAtual);
          const index = data.findIndex((d) => d.id === this.estadoAtual);
          if (index > -1) {
            data.splice(index, 1);
          }
          data.unshift(atual);
        }
        this.estados = data;
        this.$emit('success', data);
      } catch (error) {
        this.$root.$snackBar.open({
          color: 'error',
          message: 'Erro ao buscar dados, tente novamente mais tarde',
        });
        this.$emit('error');
      } finally {
        this.loading = false;
      }
    },
  },
  async created() {
    await this.getData();
  },
};

</script>

<style>

</style>
