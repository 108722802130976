var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-5 pt-0 mt-5" },
    [
      _c(
        "v-row",
        { staticClass: "mb-0", attrs: { "no-gutters": "" } },
        [
          !_vm.corretorRestrito
            ? _c(
                "v-col",
                [
                  _c(
                    "h1",
                    { staticClass: "white--text title font-weight-bold" },
                    [_vm._v("Enviamos um código de verificação")]
                  ),
                  _c(
                    "validation-observer",
                    { ref: "form" },
                    [
                      _c(
                        "v-form",
                        {
                          staticClass: "mt-4",
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.submit()
                            },
                          },
                        },
                        [
                          _c("validation-provider", {
                            attrs: {
                              name: "código",
                              vid: "codigo",
                              rules: "required|min:4",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ errors }) {
                                    return [
                                      _c("v-text-field", {
                                        staticClass: "text-uppercase",
                                        attrs: {
                                          maxlength: "4",
                                          "error-messages": errors,
                                          name: "codigo",
                                          label: "Informe o código",
                                          required: "",
                                        },
                                        model: {
                                          value: _vm.form.codigo,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "codigo", $$v)
                                          },
                                          expression: "form.codigo",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1365274333
                            ),
                          }),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-end text-capitalize d-block font-weight-bold text--white",
                            },
                            [
                              _vm.dataContadorState === 0
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "white--text d-flex text--white cursor-pointer",
                                      on: { click: _vm.enviarToken },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "mr-1 text--white" },
                                        [_vm._v("Reenviar código")]
                                      ),
                                      _c("div", {
                                        staticClass:
                                          "text-capitalize d-block font-weight-bold contador",
                                      }),
                                    ]
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticClass:
                                        "white--text d-flex text--white",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "mr-1 text--white" },
                                        [_vm._v("Reenviar código")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-capitalize d-block font-weight-bold contador",
                                        },
                                        [
                                          _vm._v(
                                            "(" +
                                              _vm._s(_vm.dataContadorState) +
                                              ")"
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                            ]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "text-none mb-4 mt-5 font-weight-bold",
                              attrs: {
                                block: "",
                                large: "",
                                type: "submit",
                                elevation: "10",
                                color: "white",
                                loading: _vm.loading,
                              },
                            },
                            [
                              _c("b", { staticClass: "primary--text" }, [
                                _vm._v("Verificar"),
                              ]),
                              _c(
                                "span",
                                { attrs: { slot: "loader" }, slot: "loader" },
                                [
                                  _c("v-progress-circular", {
                                    attrs: {
                                      indeterminate: "",
                                      width: 3,
                                      color: "primary",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.corretorRestrito ? _c("v-col") : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }