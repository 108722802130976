<template>
  <div>
    <v-form>
      <v-text-field name="busca" label="Filtrar" @input="search" clearable></v-text-field>
    </v-form>
    <async-estados :estadoAtual="estadoAtual" v-slot="{ data, loading}" @success="setData">
      <v-container class="pa-5 pt-0" dark>
        <div class="d-flex justify-center mt-10 pa-10" v-if="loading">
          <v-progress-circular indeterminate color="grey" size="50"></v-progress-circular>
        </div>
        <v-list v-else dark style="background: transparent">
          <template v-for="(estado, index) in dadosFiltrados">
            <v-list-item class="pa-2" @click="select(estado)" :key="estado.id">
              <v-list-item-icon>
                <v-icon v-if="estado.id === estadoAtual" color="yellow">mdi-map-marker</v-icon>
                <v-icon v-else>mdi-map</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  <h2 class="subtitle-1 font-weight-bold white--text">{{estado.id}}</h2>
                </v-list-item-title>
                <v-list-item-subtitle>
                  <span>{{estado.nome}}</span>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon>mdi-chevron-right</v-icon>
              </v-list-item-action>
            </v-list-item>
            <v-divider :key="index" v-if="index + 1 < data.length"></v-divider>
          </template>
        </v-list>
      </v-container>
    </async-estados>
  </div>
</template>

<script>
import TheNavBar from '@/components/TheNavBar.vue';
import AsyncEstados from '@/components/AsyncEstados.vue';
import { filterArrayObejctByString } from '@/utils/filterArray';

export default {
  components: { AsyncEstados },
  data() {
    return {
      loading: false,
      dialog: false,
      estados: [],
      dadosFiltrados: [],
    };
  },
  props: {
    estadoAtual: {
      type: String,
    },
  },
  methods: {
    select(estado) {
      this.$emit('select', estado.id);
      this.dialog = false;
    },
    setData(estados) {
      this.estados = [...estados];
      this.dadosFiltrados = [...estados];
    },
    search(value) {
      this.dadosFiltrados = filterArrayObejctByString({
        string: value,
        array: this.estados,
        keyObject: 'nome',
      });
    },
  },
};
</script>

<style>
</style>
