import api from '@/services/api';
import AppError from '@/utils/appError';

const resource = 'corretoras';

const getCorretorasPorCpf = async ({ cpf }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_CORRETORA_API_KEY } };
    const { data } = await api.get(`${resource}/listaPorCorretor/${cpf}`, config);
    return data.data ? data.data : [];
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const getCorretoraPorCpfCnpj = async ({cpfCnpj}) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_CORRETORA_API_KEY } };
    const { data } = await api.get(`${resource}/buscar/${cpfCnpj}`, config);
    return data.data ? data.data : [];
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }

}

const getAbrangenciaCorretoras = async ({ ufs, tipoCorretor, cpf }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_CORRETORA_API_KEY } };
    const { data } = await api.get(`${resource}/abrangencias/?ufs=${ufs}&tipoCorretor=${tipoCorretor}&cpf=${cpf}`, config);
    return data.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const getCorretorasParaVincular = async ({ uf, tipoCorretor, subTipoCorretor }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_CORRETORA_API_KEY } };
    const { data } = await api.get(`${resource}?uf=${uf}&tipoCorretor=${tipoCorretor}&subTipoCorretor=${subTipoCorretor}`, config);
    return data.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const listaParaVincular = async ({ cpf }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_CORRETORA_API_KEY } };
    const { data } = await api.get(`${resource}/lista-para-vincular/${cpf}`, config);
    return data.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const listaCorretorasVinculadas = async ({ cpf }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_CORRETORA_API_KEY } };
    const { data } = await api.get(`${resource}/${cpf}/corretores/solicitacoes`, config);
    return data.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const alteraStatusSolicitacao = async ({ guidSolicitacao, status }) => {
  try {
    const body = {
      statusSolicitacao: status,
    };
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_CORRETORA_API_KEY } };
    const { data } = await api.put(`${resource}/corretores/solicitacoes/${guidSolicitacao}`, body, config);
    return data.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const deletaStatusSolicitacao = async ({ guidSolicitacao }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_CORRETORA_API_KEY } };
    const { data } = await api.delete(`${resource}/corretores/solicitacoes/${guidSolicitacao}`, config);
    return data.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const solicitaAssociacaoCorretoras = async ({ cpf, corretoras }) => {
  try {
    const body = {
      corretor: { cpf },
      corretoras: corretoras.map((c) => c.cpfCnpj),
    };
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_CORRETORA_API_KEY } };
    const { data } = await api.post(`${resource}/corretores/solicitacoes`, body, config);
    return data.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const getSupervisoresCorretora = async (idCorretora, cpf) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_CORRETORA_API_KEY } };
    const { data } = await api.get(`${resource}/supervisores?idCorretora=${idCorretora}&cpf=${cpf}`, config);
    return data.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

export default {
  getCorretorasPorCpf,
  getAbrangenciaCorretoras,
  getCorretorasParaVincular,
  listaParaVincular,
  listaCorretorasVinculadas,
  alteraStatusSolicitacao,
  deletaStatusSolicitacao,
  solicitaAssociacaoCorretoras,
  getSupervisoresCorretora,
  getCorretoraPorCpfCnpj,
};
