var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-5 pt-0 mt-5" },
    [
      _c(
        "v-row",
        { staticClass: "mb-0", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            [
              _c("h1", { staticClass: "white--text title font-weight-bold" }, [
                _vm._v("Aceite termos"),
              ]),
              _c(
                "validation-observer",
                { ref: "form" },
                [
                  _c(
                    "v-form",
                    {
                      staticClass: "mt-4",
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.submit()
                        },
                      },
                    },
                    [
                      !_vm.dataCorretorState.isCorretorInterno
                        ? _c("validation-provider", {
                            attrs: {
                              name: "Senha",
                              vid: "senha",
                              rules: "required|min:8",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ errors }) {
                                    return [
                                      _c("v-text-field", {
                                        attrs: {
                                          name: "password",
                                          "append-icon": _vm.showPassword
                                            ? "mdi-eye"
                                            : "mdi-eye-off",
                                          type: _vm.showPassword
                                            ? "text"
                                            : "password",
                                          label: "Senha",
                                          "error-messages": errors,
                                          required: "",
                                        },
                                        on: {
                                          "click:append": function ($event) {
                                            _vm.showPassword = !_vm.showPassword
                                          },
                                        },
                                        model: {
                                          value: _vm.form.password,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "password", $$v)
                                          },
                                          expression: "form.password",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3344020830
                            ),
                          })
                        : _vm._e(),
                      !_vm.dataCorretorState.isCorretorInterno
                        ? _c("validation-provider", {
                            attrs: {
                              name: "Confirmar Senha",
                              vid: "confirmPassword",
                              rules: "required|confirmed:senha",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ errors }) {
                                    return [
                                      !_vm.dataCorretorState.isCorretorInterno
                                        ? _c("v-text-field", {
                                            attrs: {
                                              name: "confirmPassword",
                                              "append-icon":
                                                _vm.showConfirmPassword
                                                  ? "mdi-eye"
                                                  : "mdi-eye-off",
                                              type: _vm.showConfirmPassword
                                                ? "text"
                                                : "password",
                                              label: "Confirmar senha",
                                              "error-messages": errors,
                                              required: "",
                                            },
                                            on: {
                                              "click:append": function (
                                                $event
                                              ) {
                                                _vm.showConfirmPassword =
                                                  !_vm.showConfirmPassword
                                              },
                                            },
                                            model: {
                                              value: _vm.form.confirmPassword,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "confirmPassword",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.confirmPassword",
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1880262647
                            ),
                          })
                        : _vm._e(),
                      _c("validation-provider", {
                        attrs: {
                          name: "Termos de uso",
                          vid: "termos",
                          rules: "required",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ errors }) {
                              return [
                                _c("v-checkbox", {
                                  attrs: {
                                    dark: "",
                                    "false-value": null,
                                    "true-value": true,
                                    name: "termos",
                                    "error-messages": errors,
                                    label:
                                      "Concordo com os termos de uso e política de privacidade",
                                  },
                                  on: { change: _vm.abrirTermos },
                                  model: {
                                    value: _vm.form.termos,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "termos", $$v)
                                    },
                                    expression: "form.termos",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c(
                        "v-btn",
                        {
                          staticClass: "text-none mb-4 mt-5 font-weight-bold",
                          attrs: {
                            block: "",
                            large: "",
                            loading: _vm.loading,
                            type: "submit",
                            elevation: "10",
                            color: "white",
                          },
                        },
                        [
                          _c("b", { staticClass: "primary--text" }, [
                            _vm._v("Criar conta"),
                          ]),
                          _c(
                            "span",
                            { attrs: { slot: "loader" }, slot: "loader" },
                            [
                              _c("v-progress-circular", {
                                attrs: {
                                  indeterminate: "",
                                  width: 3,
                                  color: "primary",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }