<template>
  <v-container class="pa-5 pt-0 mt-5">
    <v-row no-gutters class="mb-0">
      <v-col>
        <h1 class="white--text title font-weight-bold">Aceite termos</h1>
        <validation-observer ref="form">
          <v-form class="mt-4" @submit.prevent="submit()">
            <validation-provider
              v-slot="{ errors }"
              name="Senha"
              vid="senha"
              rules="required|min:8"
              v-if="!dataCorretorState.isCorretorInterno"
            >
              <v-text-field
                name="password"
                v-model="form.password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                label="Senha"
                :error-messages="errors"
                @click:append="showPassword = !showPassword"
                required
              ></v-text-field>
            </validation-provider>
            <validation-provider
              v-if="!dataCorretorState.isCorretorInterno"
              v-slot="{ errors }"
              name="Confirmar Senha"
              vid="confirmPassword"
              rules="required|confirmed:senha"
            >
              <v-text-field
                v-if="!dataCorretorState.isCorretorInterno"
                name="confirmPassword"
                v-model="form.confirmPassword"
                :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showConfirmPassword ? 'text' : 'password'"
                label="Confirmar senha"
                :error-messages="errors"
                @click:append="showConfirmPassword = !showConfirmPassword"
                required
              ></v-text-field>
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              name="Termos de uso"
              vid="termos"
              rules="required"
            >
              <v-checkbox
                dark
                :false-value="null"
                :true-value="true"
                name="termos"
                v-model="form.termos"
                :error-messages="errors"
                @change="abrirTermos"
                label="Concordo com os termos de uso e política de privacidade"
              ></v-checkbox>
            </validation-provider>
            <v-btn
              block
              large
              :loading="loading"
              type="submit"
              elevation="10"
              color="white"
              class="text-none mb-4 mt-5 font-weight-bold"
            >
              <b class="primary--text">Criar conta</b>
              <span slot="loader">
                <v-progress-circular
                  indeterminate
                  :width="3"
                  color="primary"
                ></v-progress-circular>
              </span>
            </v-btn>
          </v-form>
        </validation-observer>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import usuarioService from "@/services/usuarioService";

export default {
  name: "Senha",
  data() {
    return {
      loading: false,
      showPassword: false,
      showConfirmPassword: false,
      form: {
        password: "",
        confirmPassword: "",
        termos: null,
      },
    };
  },
  computed: {
    ...mapState({
      dataCorretorState: (state) => state.cadastroCorretor.corretor,
      dataEnderecoState: (state) => state.cadastroCorretor.endereco,
      dataCorretorasState: (state) => state.cadastroCorretor.corretoras,
    }),
  },
  methods: {
    ...mapActions({
      setUserData: "cadastroCorretor/setUserData",
    }),
    async submit() {
      const dados = [];
      dados.corretor = this.dataCorretorState;
      dados.endereco = this.form;
      const corretoras = this.dataCorretorasState.map(
        (corretora) => corretora.cpfCnpj,
      );
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;
      this.loading = true;
      try {
        const data = await usuarioService.concluirCadastro({
          cpf: this.dataCorretorState.cpf,
          email: this.dataCorretorState.email,
          senha: this.dataCorretorState.isCorretorInterno
            ? null
            : this.form.password,
          corretoras,
          acessoRestrito: this.dataCorretorState.acessoRestrito,
          loginUsuarioRestrito: this.dataCorretorState.loginUsuarioRestrito,
        });
        if (data.status === 200) this.$emit("completed");
        else {
          this.loading = false;
        }
      } catch (error) {
        this.$root.$snackBar.open({ color: "error", message: error.message });
        this.loading = false;
      }
    },
    abrirTermos() {
      if (this.form.termos) {
        const url = `${process.env.VUE_APP_BASE_GTQUALI_URL}gtquali/pdf/termos_uso.pdf?api-key=${process.env.VUE_APP_GTQUALI_API_KEY}`;
        window.open(
          url,
          "_blank",
        );
      }
    },
  },
};
</script>
