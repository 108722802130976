var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "fragment",
    [
      _c(
        "v-app-bar",
        {
          attrs: {
            color: "primary",
            dark: "",
            fixed: "",
            "extension-height": "auto",
          },
          scopedSlots: _vm._u(
            [
              _vm.filter
                ? {
                    key: "extension",
                    fn: function () {
                      return [
                        _c(
                          "v-container",
                          { staticClass: "d-flex align-center pa-0" },
                          [
                            _c("v-text-field", {
                              staticClass: "mt-1",
                              attrs: {
                                disabled: _vm.disableFilter,
                                clearable: "",
                                light: "",
                                solo: "",
                                "hide-details": "",
                                "prepend-inner-icon": "mdi-magnify",
                                placeholder: _vm.placeHolder,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.$emit("filterInput", $event)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  }
                : null,
            ],
            null,
            true
          ),
        },
        [
          _c(
            "v-container",
            { staticClass: "d-flex align-center pa-0" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-3",
                  attrs: { small: "", icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("back")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                1
              ),
              _c("v-toolbar-title", { staticClass: "mt-1" }, [
                _c("div", [
                  _c(
                    "h1",
                    {
                      staticClass: "title font-weight-bold mb-1 title",
                      staticStyle: { "line-height": "1.3" },
                    },
                    [_vm._v(_vm._s(_vm.title))]
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", {
        staticClass: "header-height",
        class: { "header-height--withfilter": _vm.filter },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }