<template>
  <v-container class="pa-5 pt-0 mt-5">
    <v-row no-gutters class="mb-0">
      <v-col v-if="!corretorRestrito">
        <h1 class="white--text title font-weight-bold">Enviamos um código de verificação</h1>
        <validation-observer ref="form">
          <v-form class="mt-4" @submit.prevent="submit()">
            <validation-provider v-slot="{errors}" name="código" vid="codigo" rules="required|min:4">
              <v-text-field
                maxlength="4"
                class="text-uppercase"
                v-model="form.codigo"
                :error-messages="errors"
                name="codigo"
                label="Informe o código"
                required
              ></v-text-field>
            </validation-provider>
            <div  class="d-flex justify-end text-capitalize d-block font-weight-bold text--white">
              <div v-if="dataContadorState === 0" class="white--text d-flex text--white cursor-pointer" @click="enviarToken">
                <div class=" mr-1 text--white">Reenviar código</div>
                <div class="text-capitalize d-block font-weight-bold contador"></div>
              </div>
              <div v-else class="white--text d-flex text--white">
                <div class=" mr-1 text--white">Reenviar código</div>
                <div class="text-capitalize d-block font-weight-bold contador">({{ dataContadorState }})</div>
              </div>
            </div>
            <v-btn
              block
              large
              type="submit"
              elevation="10"
              color="white"
              class="text-none mb-4 mt-5 font-weight-bold"
              :loading="loading"
            >
              <b class="primary--text">Verificar</b>
                <span slot="loader">
                <v-progress-circular
                  indeterminate
                  :width="3"
                  color="primary"
                ></v-progress-circular>
              </span>
            </v-btn>
          </v-form>
        </validation-observer>
      </v-col>
      <v-col v-if="corretorRestrito">
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import usuarioService from '@/services/usuarioService';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'Verificacao',
  data() {
    return {
      loading: false,
      form: {
        codigo: '',
      },
    };
  },
  computed: {
    ...mapState({
      dataUserState: (state) => state.cadastroCorretor.corretor,
      dataContadorState: (state) => state.cadastroCorretor.contador,
    }),
    corretorRestrito() {
      return this.dataUserState && this.dataUserState.acessoRestrito;
    },
  },
  async created() {
    await this.enviarToken();
  },
  watch: {
    dataUserState: {
      immediate: true,
      handler: 'setDataUser',
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      setCorretor: 'cadastroCorretor/setCorretor',
      setContador: 'cadastroCorretor/setContador',
    }),
    async submit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;
      try {
        this.loading = true;
        await usuarioService.validarToken({ cpf: this.dataUserState.cpf, token: this.form.codigo });
        if (this.form.corretor.etapaCadastroInicial && this.form.corretor.etapaCadastroInicial !== '') {
          if (parseInt(this.form.corretor.etapaCadastroInicial, 10) < 5) {
            this.form.corretor.etapaCadastroInicial = '5';
          }
        } else {
          this.form.corretor.etapaCadastroInicial = '5';
        }
        await usuarioService.atualizaCorretor({ cpf: this.form.corretor.cpf, dados: this.form });
        this.$emit('next');
      } catch (error) {
        this.$root.$snackBar.open({ color: 'error', message: error.message });
        this.loading = false;
      }
    },
    async enviarToken() {
      if (!this.dataUserState.acessoRestrito) {
        if (this.dataContadorState === 0) {
          try {
            this.setContador(40);
            await usuarioService.enviarToken({ cpf: this.dataUserState.cpf });
          } catch (error) {
            this.$root.$snackBar.open({ color: 'error', message: error.message });
          }
        }
      } else {
        try {
          await usuarioService.enviarToken({ cpf: this.dataUserState.cpf });
          if (this.form.corretor.etapaCadastroInicial && this.form.corretor.etapaCadastroInicial !== '') {
            if (parseInt(this.form.corretor.etapaCadastroInicial, 10) < 5) {
              this.form.corretor.etapaCadastroInicial = '5';
            }
          } else {
            this.form.corretor.etapaCadastroInicial = '5';
          }
          await usuarioService.atualizaCorretor({ cpf: this.form.corretor.cpf, dados: this.form });
          this.$emit('next');
        } catch (error) {
          this.$root.$snackBar.open({ color: 'error', message: error.message });
        }
      }
    },
    setDataUser() {
      this.form = {
        ...this.form,
        corretor: this.$cleanObserver(this.dataUserState),
      };
    },
  },
};
</script>
<style scoped>
.contador {
  width: 25px
}
</style>
