var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-form",
        [
          _c("v-text-field", {
            attrs: { name: "busca", label: "Filtrar", clearable: "" },
            on: { input: _vm.search },
          }),
        ],
        1
      ),
      _c("async-estados", {
        attrs: { estadoAtual: _vm.estadoAtual },
        on: { success: _vm.setData },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ data, loading }) {
              return [
                _c(
                  "v-container",
                  { staticClass: "pa-5 pt-0", attrs: { dark: "" } },
                  [
                    loading
                      ? _c(
                          "div",
                          { staticClass: "d-flex justify-center mt-10 pa-10" },
                          [
                            _c("v-progress-circular", {
                              attrs: {
                                indeterminate: "",
                                color: "grey",
                                size: "50",
                              },
                            }),
                          ],
                          1
                        )
                      : _c(
                          "v-list",
                          {
                            staticStyle: { background: "transparent" },
                            attrs: { dark: "" },
                          },
                          [
                            _vm._l(
                              _vm.dadosFiltrados,
                              function (estado, index) {
                                return [
                                  _c(
                                    "v-list-item",
                                    {
                                      key: estado.id,
                                      staticClass: "pa-2",
                                      on: {
                                        click: function ($event) {
                                          return _vm.select(estado)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          estado.id === _vm.estadoAtual
                                            ? _c(
                                                "v-icon",
                                                { attrs: { color: "yellow" } },
                                                [_vm._v("mdi-map-marker")]
                                              )
                                            : _c("v-icon", [_vm._v("mdi-map")]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", [
                                            _c(
                                              "h2",
                                              {
                                                staticClass:
                                                  "subtitle-1 font-weight-bold white--text",
                                              },
                                              [_vm._v(_vm._s(estado.id))]
                                            ),
                                          ]),
                                          _c("v-list-item-subtitle", [
                                            _c("span", [
                                              _vm._v(_vm._s(estado.nome)),
                                            ]),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item-action",
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-chevron-right"),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  index + 1 < data.length
                                    ? _c("v-divider", { key: index })
                                    : _vm._e(),
                                ]
                              }
                            ),
                          ],
                          2
                        ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }