<template>
  <v-container class="pa-5 pt-0 mt-5">
    <v-row no-gutters class="mb-0">
      <v-col>
        <h1 class="white--text title font-weight-bold">Informe seus dados</h1>
        <validation-observer ref="form">
          <v-form class="mt-4" @submit.prevent="submit()">
            <v-row>
              <v-col>
                <base-text-field
                  :disabled="form.corretor.dadosSerasa === 'Dados automáticos'"
                  type="text"
                  id="nome"
                  name="Nome"
                  label="Nome*"
                  clearable
                  outlined
                  v-model="form.corretor.nome"
                  :darkTheme="true"
                  tabindex="1"></base-text-field>
                <base-text-field
                  :disabled="form.corretor.dadosSerasa === 'Dados automáticos'"
                  :autoBlur="10"
                  :rules="'date|required'"
                  inputmode="numeric"
                  type="text"
                  id="dataNascimento"
                  name="dataNascimento"
                  label="Data de nascimento*"
                  clearable
                  v-mask="'##/##/####'"
                  placeholder="dd/mm/aaaa"
                  outlined
                  :darkTheme="true"
                  v-model="form.corretor.nascimento"
                  tabindex="2"
                ></base-text-field>
                <base-text-field
                  type="email"
                  rules="required|email"
                  id="email"
                  name="E-mail"
                  v-model="form.corretor.email"
                  label="E-mail *"
                  :darkTheme="true"
                  clearable
                  outlined
                  tabindex="3"
                  v-if="!corretorRestrito"
                ></base-text-field>
                <base-text-field
                  type="tel"
                  rules="required|cellphone|ddd"
                  id="telefoneCelular"
                  name="Telefone celular"
                  v-model="form.corretor.celular"
                  label="Telefone celular *"
                  v-mask="['## #####-####', '## ####-####']"
                  clearable
                  outlined
                  persistent-hint
                  class="mb-3"
                  :darkTheme="true"
                  tabindex="4"
                  v-if="!corretorRestrito"
                ></base-text-field>
                <base-text-field
                  type="loginUsuarioRestrito"
                  id="loginUsuarioRestrito"
                  name="loginUsuarioRestrito"
                  v-model="form.corretor.loginUsuarioRestrito"
                  label="Username *"
                  :darkTheme="true"
                  clearable
                  outlined
                  tabindex="3"
                  v-if="corretorRestrito"
                ></base-text-field>
              </v-col>
            </v-row>
            <v-btn block large type="submit" elevation="10" color="white" class="text-button mb-1 mt-4 font-weight-bold" :loading="loading">
              <b class="primary--text">Avançar</b>
              <span slot="loader">
                <v-progress-circular indeterminate :width="3" color="primary"></v-progress-circular>
              </span>
            </v-btn>
          </v-form>
        </validation-observer>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import BaseTextField from "@/components/BaseTextField.vue";
import usuarioService from "@/services/usuarioService";

export default {
  name: "DadosPessoais",
  components: {
    BaseTextField,
  },
  data() {
    return {
      loading: false,
      form: {
        corretor: {
          nome: "",
          nascimento: "",
          email: "",
          celular: "",
          loginUsuarioRestrito: "",
          dadosSerasa: "",
        },
      },
    };
  },
  computed: {
    ...mapState({
      dataCadastro: (state) => state.cadastroCorretor,
      dataUserState: (state) => state.cadastroCorretor.corretor,
    }),
    corretorRestrito() {
      return this.dataUserState && this.dataUserState.acessoRestrito;
    },
  },
  watch: {
    dataCadastro: {
      immediate: true,
      handler: "setData",
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      setCorretor: "cadastroCorretor/setCorretor",
    }),
    async submit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;
      this.loading = true;
      try {
        if (this.form.corretor.etapaCadastroInicial && this.form.corretor.etapaCadastroInicial !== "") {
          if (parseInt(this.form.corretor.etapaCadastroInicial, 10) < 2) {
            this.form.corretor.etapaCadastroInicial = "2";
          }
        } else {
          this.form.corretor.etapaCadastroInicial = "2";
        }
        await usuarioService.atualizaCorretor({
          cpf: this.form.corretor.cpf,
          dados: this.form,
        });
        this.$emit("next");
        this.setCorretor(this.form.corretor);
      } catch (error) {
        this.$root.$snackBar.open({ color: "error", message: error.message });
        this.$emit("error");
      } finally {
        this.loading = false;
      }
    },
    async setData() {
      this.form = {
        ...this.$cleanObserver(this.dataCadastro),
      };
    },
  },
};
</script>
