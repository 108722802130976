<template>
  <div class="themelogout" :style="{background: $store.getters.getColor('bgColorSecundary')}">
    <v-app-bar flat color="transparent" class="pt-2" dark>
      <v-container class="d-flex align-center pa-0">
        <v-btn v-if="currentStep > 0" text class="font-weight-regular text-capitalize pl-0" exact @click="backStep">
          <v-icon class="mr-2">mdi-chevron-left</v-icon>
          <span>voltar</span>
        </v-btn>
         <v-btn v-else text class="font-weight-regular text-capitalize pl-0" exact @click="$router.go(-1)">
          <v-icon class="mr-2">mdi-chevron-left</v-icon>
          <span>Sair</span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-progress-circular
          :rotate="270"
          :size="50"
          :width="5"
          :value="calculaPorcentagemStep"
          color="primary"
        >
          <span class="white--text body-2">{{currentStep + 1}}/{{pages.length}}</span>
        </v-progress-circular>
      </v-container>
    </v-app-bar>
     <v-row no-gutters class="mb-10 d-none d-md-block" style="position: relative; z-index: 1">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <img v-if="$store.getters.getLogoClaro" :src="$store.getters.getLogoClaro" width="110" class="mt-2 mb-4" />
          <!-- <h1 class="white--text">Venda digital</h1> -->
        </v-col>
      </v-row>
    <v-slide-x-reverse-transition leave-absolute hide-on-leave>
      <component :is="pages[currentStep]" @next="nextStep"  @back="inicio" @completed="finish"></component>
    </v-slide-x-reverse-transition>
  </div>
</template>

<script>
import PreventRealodOnEditing from '@/mixins/preventReloadOnEditing';
import { mapActions, mapState } from 'vuex';
import Endereco from './Endereco.vue';
import Corretoras from './Corretoras/index.vue';
import Verificacao from './Verificacao.vue';
import Senha from './Senha.vue';
import DadosPessoais from './DadosPessoais.vue';

export default {
  name: 'IndexCadastro',
  mixins: [PreventRealodOnEditing],
  data() {
    return {
      pages: [
        DadosPessoais,
        Endereco,
        Corretoras,
        Verificacao,
        Senha],
      currentStep: 0,
      isEditing: false,
    };
  },
  computed: {
    ...mapState({
      payload: (state) => state.cadastroCorretor,
      dataUserState: (state) => state.cadastroCorretor.corretor,
    }),
    calculaPorcentagemStep() {
      return ((this.currentStep + 1) / this.pages.length) * 100;
    },
  },
  watch: {
    dataCadastro: {
      immediate: true,
      handler: 'setData',
      deep: true,
    },
  },
  created() {
    localStorage.removeItem(`${process.env.VUE_APP_NAME_AUTH}`);
    if (!this.payload.corretor.cpf) this.$router.push({ name: 'validacao' });
  },
  methods: {
    ...mapActions({
      reset: 'cadastroCorretor/reset',
      setUserData: 'cadastroCorretor/setUserData',
    }),
    nextStep() {
      if (this.currentStep <= this.pages.length) {
        this.currentStep++;
      }
    },
    backStep() {
      if (this.currentStep > 0) {
        this.currentStep--;
      }
    },
    finish() {
      const payload = { ...this.payload };
      this.$root.$snackBar.open({ color: 'success', message: 'Cadastro realizado com sucesso' });
      this.isEditing = false;
      this.$router.push({ name: 'login' });
    },
    inicio() {
      this.reset();
    },
    async setData() {
      const form = this.$cleanObserver(this.payload);
      this.form = form;
    },
  },
  mounted() {
    this.isEditing = true;
    if (this.currentStep === 0 && this.form.corretor.etapaCadastroInicial) {
      this.currentStep = parseInt(this.form.corretor.etapaCadastroInicial, 10) - 1;
    }
  },
  async beforeRouteLeave(to, from, next) {
    /**
     * Simula navegação por historico.
     * caso o formulario esteja em modo de edição e tenha uma etapa maior que zero
     */
    if (this.isEditing) {
      if (this.currentStep) {
        this.backStep();
        next(false);
        return;
      }
      if (this.payload.corretor.cpf) {
        const resp = await this.$root.$confirmdialog.open(
          'Deseja sair sem salvar?',
          'Caso saia todos os dados preenchidos serão perdidos.',
        );
        if (!resp) {
          next(false);
          return;
        }
      } else {
        next(false);
      }
    }
    // LIMPA O STATE DO CADASTRO AO SAIR DA PÁGINA
    this.reset();
    next();
  },
};
</script>
