<template>
 <fragment>
    <v-app-bar color="primary" dark fixed extension-height="auto">
    <v-container class="d-flex align-center pa-0">
      <v-btn class="mr-3" small icon dark @click="$emit('back')">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-toolbar-title class="mt-1">
        <div>
          <h1 class="title font-weight-bold mb-1 title" style="line-height: 1.3">{{title}}</h1>
        </div>
      </v-toolbar-title>
    </v-container>
    <template v-slot:extension v-if="filter">
      <v-container class="d-flex align-center pa-0">
        <v-text-field
          :disabled="disableFilter"
          class="mt-1"
          clearable
          light
          solo
          hide-details
          prepend-inner-icon="mdi-magnify"
          :placeholder="placeHolder"
          @input="$emit('filterInput', $event)"
        ></v-text-field>
      </v-container>
    </template>
  </v-app-bar>
  <div class="header-height" :class="{ 'header-height--withfilter': filter}"></div>
 </fragment>
</template>

<script>
export default {
  props: {
    filter: Boolean,
    title: {
      default: 'title',
      type: String,
    },
    placeHolder: {
      default: 'Filtrar...',
      type: String,
    },
    disableFilter: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
.header-height {
  padding: 20px;
  height: 56px;
  @media (min-width: 960px) {
    height: 70px;
  }
  &--withfilter{
  padding: 20px;
    height: 110px;
  @media (min-width: 960px) {
    height: 120px;
  }
  }
}
</style>
