<template>
  <v-container class="pa-5 pt-0 mt-5">
    <v-row no-gutters class="mb-0">
      <v-col>
        <h1 class="white--text title font-weight-bold">
          Confirme seu endereço
        </h1>
        <validation-observer ref="form">
          <v-form class="mt-4" @submit.prevent="submit()">
            <v-row>
              <v-col>
                <async-cep
                  :cep="form.endereco.cep"
                  v-slot="{ loading: loadingCep }"
                  @success="successCep($event)"
                >
                  <base-text-field
                    :autoBlur="9"
                    rules="required|min:9"
                    inputmode="numeric"
                    type="tel"
                    id="cep"
                    name="CEP"
                    label="CEP"
                    v-mask="'#####-###'"
                    outlined
                    maxlength="9"
                    v-model="form.endereco.cep"
                    :darkTheme="true"
                    :loading="loadingCep"
                  ></base-text-field>
                </async-cep>
                <base-text-field
                  rules="required"
                  id="logradouro"
                  name="Logradouro"
                  v-model="form.endereco.logradouro"
                  label="Logradouro *"
                  :darkTheme="true"
                  outlined
                >
                </base-text-field>
                <base-text-field
                  rules="required"
                  v-mask="['############']"
                  id="numero"
                  name="Número"
                  v-model="form.endereco.numero"
                  label="Número *"
                  clearable
                  outlined
                  :darkTheme="true"
                ></base-text-field>
                <base-text-field
                  rules="required"
                  id="bairro"
                  name="Bairro"
                  v-model="form.endereco.bairro"
                  label="Bairro *"
                  :darkTheme="true"
                  clearable
                  outlined
                >
                </base-text-field>
                <base-text-field
                  disabled
                  rules="required"
                  id="municipio"
                  name="Município"
                  v-model="form.endereco.cidade"
                  label="Cidade *"
                  :darkTheme="true"
                  outlined
                ></base-text-field>
                <base-text-field
                  rules="required"
                  id="uf"
                  name="UF"
                  disabled
                  v-mask="'AA'"
                  v-model="form.endereco.estado"
                  label="UF *"
                  outlined
                >
                </base-text-field>
              </v-col>
            </v-row>
            <v-btn
              block
              large
              type="submit"
              elevation="10"
              color="white"
              class="text-none mb-4 mt-5 font-weight-bold"
              :loading="loading"
            >
              <b class="primary--text">Avançar</b>
              <span slot="loader">
                <v-progress-circular
                  indeterminate
                  :width="3"
                  color="primary"
                ></v-progress-circular>
              </span>
            </v-btn>
          </v-form>
        </validation-observer>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import AsyncCep from "@/components/AsyncCepResidencial.vue";
import BaseTextField from "@/components/BaseTextField.vue";
import usuarioService from "@/services/usuarioService";
import filters from "@/filters";

export default {
  name: "Endereco",
  components: { BaseTextField, AsyncCep },
  data() {
    return {
      form: {},
      loading: false,
    };
  },
  computed: {
    ...mapState({
      dataUserState: (state) => state.cadastroCorretor.corretor,
      dataEnderecoState: (state) => state.cadastroCorretor.endereco,
    }),
  },
  watch: {
    dataEnderecoState: {
      immediate: true,
      handler: "setDataEndereco",
      deep: true,
    },
    dataUserState: {
      immediate: true,
      handler: "setDataCorretor",
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      setEndereco: "cadastroCorretor/setEndereco",
    }),
    async submit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;
      this.loading = true;
      this.setEndereco(this.form.endereco);
      try {
        if (
          this.form.corretor.etapaCadastroInicial
          && this.form.corretor.etapaCadastroInicial !== ""
        ) {
          if (parseInt(this.form.corretor.etapaCadastroInicial, 10) < 3) {
            this.form.corretor.etapaCadastroInicial = "3";
          }
        } else {
          this.form.corretor.etapaCadastroInicial = "3";
        }
        await usuarioService.atualizaCorretor({
          cpf: this.form.corretor.cpf,
          dados: this.form,
        });
        this.$emit("next");
      } catch (error) {
        this.$root.$snackBar.open({ color: "error", message: error.message });
      } finally {
        this.loading = false;
      }
    },
    async successCep(data) {
      this.form.endereco.estado = data.estado;
      this.form.endereco.cidade = data.cidade;
      this.form.endereco.bairro = data.bairro;
      this.form.endereco.logradouro = data.logradouro;
    },
    setDataEndereco() {
      this.form = {
        ...this.form,
        endereco: this.$cleanObserver(this.dataEnderecoState),
      };
    },
    setDataCorretor() {
      this.form = {
        ...this.form,
        corretor: this.$cleanObserver(this.dataUserState),
      };
    },
  },
};
</script>
