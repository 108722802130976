var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-5 pt-0 mt-5" },
    [
      _c(
        "v-row",
        { staticClass: "mb-0", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            [
              _c("h1", { staticClass: "white--text title font-weight-bold" }, [
                _vm._v("Informe seus dados"),
              ]),
              _c(
                "validation-observer",
                { ref: "form" },
                [
                  _c(
                    "v-form",
                    {
                      staticClass: "mt-4",
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.submit()
                        },
                      },
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("base-text-field", {
                                attrs: {
                                  disabled:
                                    _vm.form.corretor.dadosSerasa ===
                                    "Dados automáticos",
                                  type: "text",
                                  id: "nome",
                                  name: "Nome",
                                  label: "Nome*",
                                  clearable: "",
                                  outlined: "",
                                  darkTheme: true,
                                  tabindex: "1",
                                },
                                model: {
                                  value: _vm.form.corretor.nome,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form.corretor, "nome", $$v)
                                  },
                                  expression: "form.corretor.nome",
                                },
                              }),
                              _c("base-text-field", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: "##/##/####",
                                    expression: "'##/##/####'",
                                  },
                                ],
                                attrs: {
                                  disabled:
                                    _vm.form.corretor.dadosSerasa ===
                                    "Dados automáticos",
                                  autoBlur: 10,
                                  rules: "date|required",
                                  inputmode: "numeric",
                                  type: "text",
                                  id: "dataNascimento",
                                  name: "dataNascimento",
                                  label: "Data de nascimento*",
                                  clearable: "",
                                  placeholder: "dd/mm/aaaa",
                                  outlined: "",
                                  darkTheme: true,
                                  tabindex: "2",
                                },
                                model: {
                                  value: _vm.form.corretor.nascimento,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form.corretor,
                                      "nascimento",
                                      $$v
                                    )
                                  },
                                  expression: "form.corretor.nascimento",
                                },
                              }),
                              !_vm.corretorRestrito
                                ? _c("base-text-field", {
                                    attrs: {
                                      type: "email",
                                      rules: "required|email",
                                      id: "email",
                                      name: "E-mail",
                                      label: "E-mail *",
                                      darkTheme: true,
                                      clearable: "",
                                      outlined: "",
                                      tabindex: "3",
                                    },
                                    model: {
                                      value: _vm.form.corretor.email,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form.corretor,
                                          "email",
                                          $$v
                                        )
                                      },
                                      expression: "form.corretor.email",
                                    },
                                  })
                                : _vm._e(),
                              !_vm.corretorRestrito
                                ? _c("base-text-field", {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: [
                                          "## #####-####",
                                          "## ####-####",
                                        ],
                                        expression:
                                          "['## #####-####', '## ####-####']",
                                      },
                                    ],
                                    staticClass: "mb-3",
                                    attrs: {
                                      type: "tel",
                                      rules: "required|cellphone|ddd",
                                      id: "telefoneCelular",
                                      name: "Telefone celular",
                                      label: "Telefone celular *",
                                      clearable: "",
                                      outlined: "",
                                      "persistent-hint": "",
                                      darkTheme: true,
                                      tabindex: "4",
                                    },
                                    model: {
                                      value: _vm.form.corretor.celular,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form.corretor,
                                          "celular",
                                          $$v
                                        )
                                      },
                                      expression: "form.corretor.celular",
                                    },
                                  })
                                : _vm._e(),
                              _vm.corretorRestrito
                                ? _c("base-text-field", {
                                    attrs: {
                                      type: "loginUsuarioRestrito",
                                      id: "loginUsuarioRestrito",
                                      name: "loginUsuarioRestrito",
                                      label: "Username *",
                                      darkTheme: true,
                                      clearable: "",
                                      outlined: "",
                                      tabindex: "3",
                                    },
                                    model: {
                                      value:
                                        _vm.form.corretor.loginUsuarioRestrito,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form.corretor,
                                          "loginUsuarioRestrito",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "form.corretor.loginUsuarioRestrito",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "text-button mb-1 mt-4 font-weight-bold",
                          attrs: {
                            block: "",
                            large: "",
                            type: "submit",
                            elevation: "10",
                            color: "white",
                            loading: _vm.loading,
                          },
                        },
                        [
                          _c("b", { staticClass: "primary--text" }, [
                            _vm._v("Avançar"),
                          ]),
                          _c(
                            "span",
                            { attrs: { slot: "loader" }, slot: "loader" },
                            [
                              _c("v-progress-circular", {
                                attrs: {
                                  indeterminate: "",
                                  width: 3,
                                  color: "primary",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }