<template>
  <fragment>
    <v-toolbar dark color="primary">
      <v-container class="d-flex align-center pa-0">
        <v-btn class="mr-3" small icon dark @click="$emit('back')">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-toolbar-title class="mt-1">
          <h1 class="title font-weight-bold">Regiões</h1>
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-container>
    </v-toolbar>
    <v-container class="pa-5 pt-0 mt-5">
      <base-dialog-select-state @select="selecionaEstado" :estadoAtual="estado"/>
      <div v-if="loading" class="d-flex justify-center mt-10">
        <v-progress-circular color="blue" indeterminate></v-progress-circular>
      </div>
    </v-container>

    <!-- MODAL ESTADOS -->
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      class="d-flex"
    >
      <div class="themelogout" :style="{background: $store.getters.getColor('bgColorSecundary')}">
        <Corretoras
          v-if="showEstado"
          @back="closeDialog"
          @complete="complete"
          :regiao="regiaoSelecionada"
        ></Corretoras>
        {{ regiaoSelecionada }}
      </div>
    </v-dialog>
  </fragment>
</template>

<script>
import { filterArrayObejctByString } from '@/utils/filterArray';
import BaseDialogSelectState from '@/components/BaseDialogSelectState.vue';
import Corretoras from './Corretoras.vue';

export default {
  components: { Corretoras, BaseDialogSelectState },
  props: {
    estado: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {},
      loading: false,
      showEstado: false,
      regiaoSelecionada: {},
      dadosFiltrados: [],
      dialog: false,
      regioes: [],
    };
  },
  watch: {
    dialog(newValue) {
      if (!newValue) {
        /**
         * SetTimeout para destruir o modal de corretoras sem perder a animação de fechar,
         * assim sempre quando uma região for selecionada o modal será recriado
         */
        setTimeout(() => {
          this.showEstado = false;
        }, 300);
      }
    },
  },
  methods: {
    search(value) {
      this.dadosFiltrados = filterArrayObejctByString({
        string: value,
        array: this.regioes,
        keyObject: 'nome',
      });
    },
    selecionaEstado(sigla) {
      this.regiaoSelecionada = sigla;
      this.dialog = true;
      this.showEstado = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    selecionaRegiao(regiao) {
      this.regiaoSelecionada = { ...regiao };
    },
    complete(corretoras) {
      this.dialog = false;
      this.$emit('complete', corretoras);
    },
  },
};
</script>
